@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  body {
    @apply bg-black;
  }
}

* {
  outline: none;
  text-decoration: none;
}

:root {
  --footer-height: 4rem;
  ---content-height: calc(100vh - var(--footer-height));
}

.footer-height {
  min-height: var(--footer-height);
}

.content-height {
  min-height: var(---content-height);
}

.loader-container {
  width: 100%;
  height: 2.5rem;
  background-color: white;
}

.loader-bar {
  height: 100%;
  width: 0;
  background-color: rgb(255, 22, 22);
  animation: loaderAnimation linear forwards;
}

.pulse-animation {
  animation: pulse 1.5s infinite;
}

@keyframes loaderAnimation {
  0% {
    width: 0;
  }
  100% {
    width: 98%;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  30% {
    transform: scale(1.05);
  }
  60% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(1.001);
  }
  100% {
    transform: scale(1);
  }
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: rgb(255, 22, 22);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: rgb(255, 22, 22);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

@media screen and (min-width: 768px) {
  .lds-heart {
    display: inline-block;
    position: relative;
    width: 160px;
    height: 160px;
    transform: rotate(45deg);
    transform-origin: 80px 80px;
  }

  .lds-heart div {
    top: 64px;
    left: 64px;
    position: absolute;
    width: 64px;
    height: 64px;
    background: rgb(255, 22, 22);
    animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .lds-heart div:after,
  .lds-heart div:before {
    content: " ";
    position: absolute;
    display: block;
    width: 64px;
    height: 64px;
    background: rgb(255, 22, 22);
  }

  .lds-heart div:before {
    left: -48px;
    border-radius: 50% 0 0 50%;
  }

  .lds-heart div:after {
    top: -48px;
    border-radius: 50% 50% 0 0;
  }
}
